<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{show_actives ? (show_waiting ? 'Lista de Espera' : 'Alumnos Activos') : 'Alumnos Inactivos'}}
        <div class="card-header-actions">
          <CButton v-show="show_actives && !show_waiting" color="dark" size="sm" @click="showDivide()">Dividir</CButton>
          <CButton style="margin-left: 10px;" v-if="basic && !show_actives" color="info" size="sm" @click="showActives()">Ver Activos</CButton>
          <CButton style="margin-left: 10px;" v-if="basic && show_actives && show_waiting" color="info" size="sm" @click="showActives()">Ver Activos</CButton>
          
          <CButton style="margin-left: 10px;" v-if="basic && show_actives && !show_waiting" color="warning" size="sm" @click="showWaiting()">Ver Lista de Espera</CButton>
          <CButton style="margin-left: 10px;" v-if="basic && show_actives && !show_waiting" color="danger" size="sm" @click="showInactives()">Ver Inactivos</CButton>

          <CButton v-if="basic" style="margin-left: 10px;" color="success" size="sm" @click="downloadExcel({})">Descargar Excel</CButton>

          <CButton v-if="basic && detailed_students.length > 0" color="info" size="sm" @click="changeView('detailed')" style="margin-left: 10px;">Vista Completa</CButton>
          <CButton v-if="!basic" color="info" size="sm" @click="changeView('basic')" style="margin-left: 10px;">Volver</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="students"
        :fields="basic_fields"
        :items-per-page="10"
        :dark="dark"
        pagination
        :tableFilter="{label: 'Buscar: ', placeholder: ' '}"
        v-if="basic && show"
      >
        <template #nationality="{item}"><td class="w200 text-center" style="text-transform: uppercase;">{{item.nationality ? item.nationality : '-'}}<br />{{item.second_nationality ? item.second_nationality : ''}}</td></template>
        <template #name="{item}"><td style="text-transform: uppercase;">{{item.name}}</td></template>
        <template #customer_name="{item}"><td style="text-transform: uppercase;">{{item.customer_name}}<br />{{item.customer_phone}}</td></template>
        <template #position="{item}"><td class="w100 text-center">{{item.position}}</td></template>
        <template #observations="{item}"><td class="w400 text-left">
          <CButton style="float: right;" color="dark" size="sm" @click="editObservations(item)" v-c-tooltip="{content: 'Agregar Observaciones', placement: 'top'}"><CIcon name="cil-pencil"/></CButton>
          <p style="vertical-align: middle; width: 330px;" align="justify">
            {{item.observations ? item.observations : ''}}
          </p>
        </td></template>
        <template #gender="{item}"><td class="w100 text-center">{{item.gender}}</td></template>
        <template #birthdate="{item}"><td class="w100 text-center">{{item.birthdate | date}}</td></template>
        <template #contract="{item}">
          <td class="table_actions w150">
            <CButton v-if="show_actives && item.is_active" color="info" size="sm" @click="downloadContract(item.contract_url)" v-c-tooltip="{content: 'Ver Contrato', placement: 'top'}">Contrato</CButton><br />
            <CButton v-if="show_actives && item.is_active" color="info" size="sm" @click="downloadResponsive(item.responsive_url)" v-c-tooltip="{content: 'Ver Responsiva', placement: 'top'}">Responsiva</CButton><br />
          </td>
        </template>
        <template #actions="{item}">
          <td class="table_actions w150">
            <CButton v-if="!show_actives && !item.is_active" color="success" size="sm" @click="activeModal(item)" v-c-tooltip="{content: 'Reactivar', placement: 'top'}"><CIcon name="cil-check"/></CButton>
            <CButton v-if="item.is_waiting && item.is_active == 1" color="success" size="sm" @click="reactiveModal(item)" v-c-tooltip="{content: 'Reactivar', placement: 'top'}"><CIcon name="cil-clock"/></CButton>

            <CButton v-if="show_actives && item.is_active" color="info" size="sm" @click="showStudent(item)" v-c-tooltip="{content: 'Ver Alumno', placement: 'top'}">Ver Alumno</CButton><br />
            <CButton v-if="show_actives && item.is_active" color="success" size="sm" @click="userModal(item)" v-c-tooltip="{content: 'Editar Usuario', placement: 'top'}"><CIcon name="cil-https"/></CButton>
            <CButton v-if="show_actives && !item.is_waiting" color="warning" size="sm" @click="waitingModal(item)" v-c-tooltip="{content: 'Lista de Espera', placement: 'top'}"><CIcon name="cil-clock"/></CButton>
            <CButton v-if="show_actives && item.is_active" color="danger" size="sm" @click="deactiveModal(item)" v-c-tooltip="{content: 'Dar de Baja', placement: 'top'}"><CIcon name="cil-ban"/></CButton>
          </td>
        </template>
      </CDataTable>
        <div :class="fullscreen ? 'fullscreen' : ''">
          <div style="width: 100%">
            <div style="float: right;position: fixed;z-index: 100000;margin: 0;top: 0;right: 0;">
              <CButton v-if="!basic" color="info" size="sm" @click="changeView('basic')" style="margin-top: 10px;margin-right: 25px;">Cerrar</CButton><br /><br /><br /><br />
            </div>
              <CDataTable
                style="overflow: auto;"
                :hover="hover"
                :striped="striped"
                :border="border"
                :small="small"
                :fixed="fixed"
                :items="detailed_students"
                :fields="fields"
                :items-per-page="1000"
                :itemsPerPageSelect="false"
                :dark="dark"
                pagination
                sorter
                columnFilter
                :tableFilter="{label: 'Buscar: ', placeholder: ' '}"
                class="students"
                v-if="!basic && show"
                :download="true"
                @downloading="downloading"

              >
                <template #[field.key]="{item, index}" v-for="(field,i) in fields" >
                  <td class="w400 text-left" v-if="field.key == 'observations'" :key="'td-1-'+i" :data-index="index"> 
                    <CButton style="float: right;" color="dark" size="sm" @click="editObservations(item)" v-c-tooltip="{content: 'Agregar Observaciones', placement: 'top'}"><CIcon name="cil-pencil"/></CButton>
                    <p style="vertical-align: middle; width: 330px;" align="justify">
                      {{item.observations ? item.observations : ''}}
                    </p>
                  </td>
                  <td class="w50 text-left" v-if="field.type == 'position'" :key="'td-2-'+i">
                    <p style="vertical-align: middle; width: 50px;" align="justify">
                      {{item.position ? item.position : ''}}
                    </p>
                  </td>
                  <td class="w400 text-left" v-if="field.key == 'comments'" :key="'td-3-'+i">
                    <p style="vertical-align: middle; width: 330px;" align="justify">
                      {{item.comments ? item.comments : ''}}
                    </p>
                  </td>
                  <td :key="'td-4-'+i" class="text-left" style="min-width: 300px; font-size: 14px;" v-if="field.type == 'text' && field.key != 'observations' && field.key != 'comments'" @click="editTextCell(item, field)">
                    <span v-if="!item['edit_'+field.key]">{{item[field.key]}}</span>
                    <CButton style="float: right; height: 32px; margin-top: 1px;" v-if="item['edit_'+field.key]" color="info" size="sm" @click="updateTextCell(item, field, item[field.key])" v-c-tooltip="{content: 'Guardar', placement: 'top'}"><CIcon name="cil-save"/></CButton>
                    <CInput
                      type="text"
                      v-show="item['edit_'+field.key]"
                      v-model="item[field.key]"
                      label=""
                      :lazy="false"
                      placeholder=""
                      style="width: 270px;"
                      /> 
                  </td>
                  <td :key="'td-5-'+i" class="text-left" style="min-width: 200px; font-size: 14px;" v-if="field.type == 'date'" @click="editDateCell(item, field)">
                    <span v-if="!item['edit_'+field.key]">{{item[field.key] | date}}</span>
                    <CButton style="float: right; height: 32px; margin-top: 1px;" v-if="item['edit_'+field.key]" color="info" size="sm" @click="updateDateCell(item, field, item[field.key])" v-c-tooltip="{content: 'Guardar', placement: 'top'}"><CIcon name="cil-save"/></CButton>
                    <v-date-picker
                        v-show="item['edit_'+field.key]"
                        :id="'edit_'+field.key"
                        :name="'edit_'+field.key"
                        class="date-picker"
                        v-model="item[field.key]"
                        locale="es-MX" size="sm"
                        style="width: 270px;"
                    />
                  </td> 
                  <td :key="'td-6-'+i" class="text-left" style="min-width: 200px; font-size: 14px;" v-if="field.type == 'number'" @click="editTextCell(item, field)">
                    <span v-if="!item['edit_'+field.key]">{{item[field.key]}}</span>
                    <CButton style="float: right; height: 32px; margin-top: 1px;" v-if="item['edit_'+field.key]" color="info" size="sm" @click="updateTextCell(item, field, item[field.key])" v-c-tooltip="{content: 'Guardar', placement: 'top'}"><CIcon name="cil-save"/></CButton>
                    <CInput
                      type="number"
                      v-show="item['edit_'+field.key]"
                      v-model="item[field.key]"
                      label=""
                      :lazy="false"
                      placeholder=""
                      style="width: 270px;"
                    /> 
                  </td>
                  <td :key="'td-7-'+i" class="text-left" style="min-width: 300px; font-size: 14px;" v-if="field.type == 'options'" @click="editOptionsCell(item, field)">
                    <span v-if="!item['edit_'+field.key]">{{item[field.key]}}</span>
                    <CButton style="float: right; height: 32px; margin-top: 1px;" v-if="item['edit_'+field.key]" color="info" size="sm" @click="updateOptionsCell(item, field, item[field.key])" v-c-tooltip="{content: 'Guardar', placement: 'top'}"><CIcon name="cil-save"/></CButton>
                    <CSelect
                      v-if="item['edit_'+field.key]"
                      :options="item['options_'+field.key]"
                      style="width: 270px;"
                      :value="value"
                      @change="changeOptionsCell($event, item, field)"
                    />
                  </td>
                  <td :key="'td-8-'+i" class="text-center" style="min-width: 300px; font-size: 14px;" v-if="field.type == 'file'">
                    <CButton v-if="item[field.key]" color="success" size="sm" @click="downloadFile(item[field.key])" style="margin-right: 10px; !important;">Descargar Archivo</CButton> 
                    <CButton color="info" size="sm" @click="uploadModal(item, field)">Cargar Archivo</CButton>
                  </td>
                </template>
              </CDataTable>
          </div>
        </div>
    </CCardBody>

    <DeactiveModal ref="deactiveModal" @active="activeProcess" @deactive="deactiveProcess"></DeactiveModal>
    <WaitingModal ref="waitingModal" @active="activeProcess" @waiting="waitingProcess"></WaitingModal>
    <DivideModal :students="students" ref="divideModal" @divide="divideProcess"></DivideModal>
    <EditObservations ref="observationsModal" @observations="updateObservations"></EditObservations>
    <FileUpload ref="uploadModal" @upload="upload"></FileUpload>
    <UserModal ref="userModal" @send="sendWelcomeMessage" @update="userUpdateProcess"></UserModal>
  </CCard>
</template>

<script>
import DeactiveModal from './DeactiveModal.vue';
import DivideModal from './DivideModal.vue'
import WaitingModal from './WaitingModal.vue'
import EditObservations from './EditObservations.vue'
import FileUpload from './FileUpload.vue'
import UserModal from './UserModal.vue'
import ws from '../../../services/travels';
import users_ws from '../../../services/users';
import store from '../../../store'

import { DatePicker } from 'v-calendar'

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss")); 
let today2 = new moment(today.format("YYYY-MM-DD HH:m:ss")).subtract(1, 'month');

export default {
  name: 'StudentsTable',
  components: { DeactiveModal, WaitingModal, DivideModal, EditObservations, FileUpload, UserModal, 'v-date-picker': DatePicker},
  props: {
    permissions: Array,
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    id: 0,
    travel: {},
  },
  data() {
    return {
      fields: [
        {key: 'position', label: 'No.'},
        {key: 'name', label: 'Nombre del Alumno'},
        {key: 'customer_name', label: 'Padre, Madre o Tutor'},
        {key: 'gender', label: 'Sexo'},
        {key: 'birthdate', label: 'Fecha de Nacimiento'},
        {key: 'nationality', label: 'Nacionalidad'},
        {key: 'observations', label: 'Observaciones'},
        {key: 'contract', label: 'Contrato'},
        {key: 'actions', label: 'Acciones', class: 'actions', sorter: false, filter: false},
      ],
      basic_fields: [
          {key: 'position', label: 'No.'},
          {key: 'name', label: 'Nombre del Alumno'},
          {key: 'customer_name', label: 'Padre, Madre o Tutor'},
          {key: 'gender', label: 'Sexo'},
          {key: 'birthdate', label: 'Fecha de Nacimiento'},
          {key: 'nationality', label: 'Nacionalidad'},
          {key: 'observations', label: 'Observaciones'},
          {key: 'contract', label: 'Contrato'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
      ],
      toasts: [],
      students: [],
      detailed_students: [],
      show_actives: true,
      show_waiting: false,
      basic: true,
      show: true,
      value: '',
      fullscreen: false
    }
  },
  mounted: async function() {
    let _this = this;

    setTimeout(async function(){
        let response = await ws.activeStudents(_this.id);

        if(response.type == "success"){
          _this.students = response.data;
        }

        let response2 = await ws.activeDetailedStudents(_this.id);

        if(response2.type == "success"){
          _this.detailed_students = response2.data;
          _this.fields = response2.fields;
        }
      }, 100);
  },
  methods: {
    async showDivide () {
      this.$refs.divideModal.show(this.id);
    },
    async divideProcess () {
      return true;      
    },
    async changeView(type){
      if(type == "basic"){
        this.basic = true;
        this.fullscreen = false;

        let _this = this;
        
        this.detailed_students.forEach(s => {
          this.fields.forEach(f => {
            if(s["edit_"+f.key] && (item.id != s.id || field.key != f.key)){
              if(f.type == "text") _this.updateTextCell(s, f, s[f.key]);
              if(f.type == "date") _this.updateDateCell(s, f, s[f.key]);
              if(f.type == "options") _this.updateOptionsCell(s, f, s[f.key]);
            }
            s["edit_"+f.key] = false;    
          });
        });
        
        setTimeout(async function(){
          let response2 = await ws.activeStudents(_this.id);

          if(response2.type == "success"){
            _this.students = response2.data;
          }
        }, 100);
      }
      else{
        this.basic = false;
        this.fullscreen = true;
      }
    },
    async editTextCell (item, field) {
      if(item["edit_"+field.key]){
        return;
      }
      
      let _this = this;
      this.detailed_students.forEach(s => {
        this.fields.forEach(f => {
          if(s["edit_"+f.key] && (item.id != s.id || field.key != f.key)){
            if(f.type == "text") _this.updateTextCell(s, f, s[f.key]);
            if(f.type == "date") _this.updateDateCell(s, f, s[f.key]);
            if(f.type == "options") _this.updateOptionsCell(s, f, s[f.key]);
          }
          s["edit_"+f.key] = false;    
        });
      });

      item["edit_"+field.key] = true;
    },
    async updateTextCell (item, field, value) {
      const formData = new FormData();

      formData.append("travel_id", this.travel.id);
      formData.append("student_id", item.id);
      formData.append("question_id", field.key);
      formData.append("value", value);

      let response = await ws.storeAnswer(formData);

      if(response.type == "success"){
        setTimeout(async function(){
          let data = response.data;
          item["edit_"+field.key] = false;
          item[data.code] = data.value;
          item[data.question] = data.value;
        }, 10);
      }

      this.showToast(response.type, response.message);
    },
    async editDateCell (item, field) {
      if(item["edit_"+field.key]){
        return;
      }

      let _this = this;

      this.detailed_students.forEach(s => {
        this.fields.forEach(f => {
          if(s["edit_"+f.key] && (item.id != s.id || field.key != f.key)){
            if(f.type == "text") _this.updateTextCell(s, f, s[f.key]);
            if(f.type == "date") _this.updateDateCell(s, f, s[f.key]);
            if(f.type == "options") _this.updateOptionsCell(s, f, s[f.key]);
          }
          s["edit_"+f.key] = false;    
        });
      });

      item["edit_"+field.key] = true;

      let date = moment(item[field.key], "YYYY-MM-DD");

      item[field.key] = date.toDate();
    },
    async updateDateCell (item, field, value) {
      const formData = new FormData();

      formData.append("travel_id", this.travel.id);
      formData.append("student_id", item.id);
      formData.append("question_id", field.key);

      let date = new moment(value);
      formData.append("value", date.format("YYYY-MM-DD"));

      let response = await ws.storeAnswer(formData);

      if(response.type == "success"){
        setTimeout(async function(){
          let data = response.data;
          item["edit_"+field.key] = false;
          item[data.code] = data.value;
          item[data.question] = data.value;
        }, 10);
      }

      this.showToast(response.type, response.message);
    },
    async editOptionsCell (item, field) {
      if(item["edit_"+field.key]){
        return;
      }
      
      let _this = this;

      _this.detailed_students.forEach(s => {
        _this.fields.forEach(f => {
          if(s["edit_"+f.key] && (item.id != s.id || field.key != f.key)){
            if(f.type == "text") _this.updateTextCell(s, f, s[f.key]);
            if(f.type == "date") _this.updateDateCell(s, f, s[f.key]);
            if(f.type == "options") _this.updateOptionsCell(s, f, s[f.key]);
          }

          s["edit_"+f.key] = false;    
        });
      });

      let response = await ws.getOptions(field.key);

      if(response.type == "success"){
        let options = response.data;

        item['options_'+field.key] = options;

        setTimeout(async function(){
          _this.value = item[field.key];
          item["edit_"+field.key] = true;
        }, 10);
      }
    },
    changeOptionsCell(event, item, field){
      let _this = this;
      setTimeout(async function(){
          _this.value = event.target.value;
          item[field.key] = event.target.value;
      }, 10);
    },
    async updateOptionsCell (item, field, value) {
      const formData = new FormData();

      formData.append("travel_id", this.travel.id);
      formData.append("student_id", item.id);
      formData.append("question_id", field.key);
      formData.append("value", value);

      let response = await ws.storeAnswer(formData);

      this.value = '';

      if(response.type == "success"){
        setTimeout(async function(){
          let data = response.data;
          item["edit_"+field.key] = false;
          item[data.code] = data.value;
          item[data.question] = data.value;
        }, 100);
      }

      this.showToast(response.type, response.message);
    },
    async storePayment (item) {
      const formData = new FormData();

      formData.append("student_id", item.id);
      formData.append("payment_id", this.payments[0].id);
      formData.append("amount", item.amount);

      let response = await ws.storePayment(formData);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async downloading (students) {
      let student_ids = [];
      students.forEach(student => {
        student_ids.push(student.id);
      });

      let response = await ws.downloadFiltered(this.id, student_ids);

      if(response.type == "success"){
        let link = response.data;
      }
    },
    async downloadExcel () {
      let type = "actives";

        if(this.show_actives){
          let type = "actives";
        }
        else{
          if(!this.show_actives && this.show_waiting){
            let type = "waiting";
          }
          else{
            let type = "inactives";
          }
        }

        let response = await ws.download(this.id, type);

        if(response.type == "success"){
          let link = response.data;
        }
    },
    async downloadContract (url) {
      window.open(url, '_blank');
    },
    async downloadResponsive (url) {
      window.open(url, '_blank');
    },
    async showActives () {
      this.show_actives = true;
      this.show_waiting = false;

      let _this = this;

      setTimeout(async function(){
        let response2 = await ws.activeStudents(_this.id);

        if(response2.type == "success"){
          _this.students = response2.data;
        }
      }, 100);
    },
    async showWaiting () {
      this.show_actives = true;
      this.show_waiting = true;

      let _this = this;

      setTimeout(async function(){
        let response2 = await ws.waitingStudents(_this.id);

        if(response2.type == "success"){
          _this.students = response2.data;
        }
      }, 100);
    },
    async showInactives () {
      this.show_actives = false;
      this.show_waiting = false;

      let _this = this;

      setTimeout(async function(){
        let response2 = await ws.deactiveStudents(_this.id);

        if(response2.type == "success"){
          _this.students = response2.data;
        }
      }, 100);
    },
    editObservations (item) {
      this.$refs.observationsModal.show("Observaciones", item.id, item.name, item.observations);
    },
    activeModal (item) {
      this.$refs.deactiveModal.show("Reactivar Alumno", item.id, item.name, item.observations, item.is_active);
    },
    deactiveModal (item) {
      this.$refs.deactiveModal.show("Desactivar Alumno", item.id, item.name, item.observations, item.is_active);
    },
    waitingModal (item) {
      this.$refs.waitingModal.show("Lista de Espera", item.id, item.name, item.observations, item.is_waiting);
    },
    reactiveModal (item) {
      this.$refs.waitingModal.show("Reactivar Alumno", item.id, item.name, item.observations, item.is_active);
    },
    userModal (item) {
      this.$refs.userModal.updateModal(item);
    },
    async userUpdateProcess(data) {
      this.loading();
      
      let response = await users_ws.update(data);

      this.loaded();
      this.showToast(response.type, response.message);
    },
    uploadModal (item, field) {
      this.$refs.uploadModal.show("Cargar Archivo", item, field);
    },
    upload (item, field, data) {
      item[field.key] = data;
    },
    downloadFile (url) {
      var link = document.createElement('a');
      link.href = url;
      link.click();
    },
    async sendWelcomeMessage(item) {
      this.loading();
      let response = await ws.sendWelcomeMessage({student_id: item.student_id});
      
      this.loaded();
      this.showToast(response.type, response.message+" ("+item.email.toLowerCase()+")");
    },
    async activeProcess(id, observations) {
      let response = await ws.activeStudent({id: id, observations: observations});
      
      if(response.type == "success"){
        let response2 = await ws.activeStudents(this.id);

        if(response2.type == "success"){
          this.students = response2.data;
        }
      }

      this.show_actives = true;
      this.show_waiting = false;

      this.showToast(response.type, response.message);
    },
    async deactiveProcess(id, observations) {
      let response = await ws.deactiveStudent({id: id, observations: observations});
      
      if(response.type == "success"){
        let response2 = await ws.deactiveStudents(this.id);

        if(response2.type == "success"){
          this.students = response2.data;
        }
      }

      this.show_actives = false;
      this.show_waiting = false;

      this.showToast(response.type, response.message);
    },
    async waitingProcess(id, observations) {
      let response = await ws.waitStudent({id: id, observations: observations});
      
      if(response.type == "success"){
        let response2 = await ws.waitingStudents(this.id);

        if(response2.type == "success"){
          this.students = response2.data;
        }
      }

      this.show_actives = true;
      this.show_waiting = true;

      this.showToast(response.type, response.message);
    },
    async updateObservations(id, observations) {
      let response = await ws.updateObservations({id: id, observations: observations});
      
      if(response.type == "success"){
        let response2 = await ws.activeStudents(this.id);

        if(response2.type == "success"){
          this.students = response2.data;
        }

        let response3 = await ws.activeDetailedStudents(this.id);

        if(response3.type == "success"){
          this.detailed_students = response3.data;
          this.fields = response3.fields;
        }
      }

      this.show_actives = true;
      this.show_waiting = true;

      this.showToast(response.type, response.message);
    },
    showStudent (item) {
      this.$router.push({path: '/students/'+item.id+'/show'})
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
